import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import MobileMenu from "./MobileHeader";
import { useLocation } from "react-router-dom";
import Logo from "../assets/octa_logo.png";
import WhiteLogo from "../assets/white-logo.png";
import { useAuth } from "../hooks/useAuth";
import { MdDashboard } from "react-icons/md";
import { FaCreditCard } from "react-icons/fa";
import { MdAccountCircle } from "react-icons/md";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { IoLogOutOutline } from "react-icons/io5";

export default function Header() {
  const { user, dispatch } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const renderNav = (targetUrl, text, Icon) => {
    return (
      <Link
        to={targetUrl}
        className={
          location.pathname === targetUrl
            ? "font-medium rounded-md text-[#280134] underline hover:text-gray-200 px-4 py-3 flex items-center"
            : "font-medium text-gray-500 hover:text-gray-200 px-4 py-3 flex items-center"
        }
      >
        {Icon && (
          <Icon
            size={26}
            color={location.pathname === targetUrl ? "#280134" : "grey"}
          />
        )}
        {text}
      </Link>
    );
  };
  // ? "btn-sm p-[10px] bg-[#280134] hover:bg-teal-700 ml-3 text-white bg-gradient-to-r from-[#280134] via-[#280134] to-[#280134] hover:bg-gradient-to-br rounded-sm shadow-[#280134]/50 d[#280134]-lg [#280134]w-#280134-800/80 font-medium text-md px-5 py-2.5 text-center mr-2 mb-2"

  //   font-medium text-[#280134] hover:text-gray-200 px-4 py-3 flex items-center

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  if (location.pathname.includes("/payment/")) {
    return null;
  }
  // if (
  //   location.pathname === "/login" ||
  //   location.pathname === "/signup" ||
  //   location.pathname === "/forgot-password"
  // ) {
  //   return (
  //     <header className="absolute w-full z-30">
  //       <div className="max-w-6xl mx-auto px-4 sm:px-6">
  //         <div className="flex items-center justify-between h-20">
  //           {/* Site branding */}
  //           <div className="shrink-0 mr-4">
  //             {/* Logo */}
  //             <Link to="/" className="block" aria-label="Cruip">
  //               <img
  //                 src={Logo}
  //                 alt="logo"
  //                 className="w-10 h-10 fill-current text-[#280134]"
  //               />
  //             </Link>
  //           </div>
  //           <MobileMenu />
  //         </div>
  //       </div>
  //     </header>
  //   );
  // }

  return (
    <>
      <header class="hidden md:flex md:grow flex-wrap md:justify-start md:flex-nowrap z-50 w-full py-7">
        <nav
          class="relative max-w-7xl w-full flex flex-wrap md:grid md:grid-cols-12 basis-full items-center px-4 md:px-6 md:px-8 mx-auto"
          aria-label="Global"
        >
          <div class="md:col-span-3">
            <Link
              class="flex-none rounded-xl text-xl inline-block font-semibold focus:outline-none focus:opacity-80"
              to="/"
            >
              <img src={Logo} class="w-[185px] h-auto" />
            </Link>
          </div>

          <div class="flex items-center gap-x-2 ms-auto py-1 md:ps-6 md:order-3 md:col-span-3">
            <button
              type="button"
              onClick={() => navigate("/contact-us")}
              class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-xl border border-transparent bg-blue-700 shadow-lg text-white transition disabled:opacity-50 disabled:pointer-events-none focus:outline-none"
            >
              Contact us
            </button>
            <div class="md:hidden">
              <button
                type="button"
                class="hs-collapse-toggle size-[38px] flex justify-center items-center text-sm font-semibold rounded-xl border border-gray-200 text-black hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none darks:text-white darks:border-neutral-700 darks:hover:bg-neutral-700"
                data-hs-collapse="#navbar-collapse-with-animation"
                aria-controls="navbar-collapse-with-animation"
                aria-label="Toggle navigation"
              >
                <svg
                  class="hs-collapse-open:hidden flex-shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="3" x2="21" y1="6" y2="6" />
                  <line x1="3" x2="21" y1="12" y2="12" />
                  <line x1="3" x2="21" y1="18" y2="18" />
                </svg>
                <svg
                  class="hs-collapse-open:block hidden flex-shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
              </button>
            </div>
          </div>
          <div
            id="navbar-collapse-with-animation"
            class="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow md:block md:w-auto md:basis-auto md:order-2 md:col-span-6"
          >
            <div class="flex flex-col gap-y-4 gap-x-0 mt-5 md:flex-row md:justify-center md:items-center md:gap-y-0 md:gap-x-7 md:mt-0">
              <div>
                <Link
                  className={`relative inline-block text-black darks:text-white ${
                    location.pathname === "/about-us" &&
                    "before:absolute before:bottom-0.5 before:start-0 before:-z-[1] before:w-full before:h-1 before:bg-blue-700"
                  }`}
                  to="/about-us"
                >
                  About us
                </Link>
              </div>
              <div>
                <Link
                  className={`relative inline-block text-black darks:text-white ${
                    location.pathname === "/unk" &&
                    "before:absolute before:bottom-0.5 before:start-0 before:-z-[1] before:w-full before:h-1 before:bg-[#8B4000]"
                  }`}
                  to="#"
                >
                  Jobs
                </Link>
              </div>
              <div>
                <Link
                  className={`relative inline-block text-black darks:text-white ${
                    location.pathname === "/unk" &&
                    "before:absolute before:bottom-0.5 before:start-0 before:-z-[1] before:w-full before:h-1 before:bg-[#8B4000]"
                  }`}
                  to="#"
                >
                  Investors
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <MobileMenu />
    </>
  );
}
