import React from "react";
import './home.animation.css';

export default function AboutUs() {
  return (
    <div className="animated-text">
      <div className="bg-white py-6 sm:py-8 lg:py-12">
        <div className="mx-auto max-w-screen-xl px-4 md:px-8">
          <div className="grid gap-8 md:grid-cols-2 lg:gap-12">
            <div className="md:pt-8 lg:flex lg:flex-col lg:justify-center">
              <p className="text-center font-bold text-blue-600 md:text-left">
                Who We Are
              </p>

              <h1 className="mb-4 text-center text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6 md:text-left">
                Innovating Solutions for the Modern World
              </h1>

              <p className="mb-6 text-gray-500 sm:text-lg md:mb-8">
                At Octagonic, we are dedicated to transforming industries
                through innovative technology solutions. Our mission is to
                deliver cutting-edge services that drive efficiency, growth, and
                success. Whether it's in shipping logistics, e-commerce, tech
                consulting, or fintech, we are committed to simplifying complex
                processes and providing top-notch solutions to meet the evolving
                needs of our clients.
              </p>
            </div>
            <div>
              <div className="h-64 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-auto">
                <img
                  src="https://images.pexels.com/photos/3182812/pexels-photo-3182812.jpeg?auto=compress&cs=tinysrgb&w=800"
                  alt="Innovative Solutions"
                  className="h-full w-full object-cover object-center"
                />
              </div>
            </div>
            <div className="md:col-span-2">
              <h2 className="mb-2 text-center text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4 md:text-left">
                Our Mission
              </h2>

              <p className="mb-6 text-gray-500 sm:text-lg md:mb-8">
                Our mission is to empower businesses and individuals with
                innovative technology that simplifies and enhances their
                operations. We are committed to delivering secure, efficient,
                and user-friendly solutions that address the unique challenges
                of various industries. By staying at the forefront of
                technology, we aim to provide exceptional service and support,
                ensuring our clients achieve their goals with ease.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
