import Lottie from "lottie-react";
import ErrorAni from "../assets/error-ani.json";

export const NoData = () => {
  return (
    <div className="w-full">
      <section className="bg-gray-50 h-screen darks:bg-gray-900 flex flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
          <div className="w-full bg-white mt-[30px] rounded-lg shadow darks:border md:mt-[30px] sm:max-w-md xl:p-0 darks:bg-gray-800 darks:border-gray-700">
            <div
              className="p-6 space-y-4 md:space-y-6 sm:p-8 d-flex"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Lottie animationData={ErrorAni} style={{ height: 100 }} />;
              <p>An error occured! Please refresh the browser</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
