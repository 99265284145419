import React, { useState } from "react";
import './style.scss'
import { Link } from "react-router-dom";
import Logo from "../assets/octa_logo.png";
import WhiteLogo from "../assets/white_logo2.png";
import Icon from "../assets/automation.png";
import Icon2 from "../assets/delivery-truck.png";
import OctaShipLogo from "../assets/octaship.png";
import FintechLogo from "../assets/payment.png";
import Icon4 from "../assets/money (1).png";
import PromotionIc from "../assets/promotion.png";
import HandShake from "../assets/handshake.png";
import LoveIco from "../assets/love.png";

import { useAuth } from "../hooks/useAuth";

import { BsInstagram } from "react-icons/bs";


const team = [
  {
    avatar:
      "https://images.unsplash.com/photo-1579017331263-ef82f0bbc748?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=685&q=80",
    name: "Bashir Ajingi",
    title: "CEO",
  },
  {
    avatar:
      "https://images.unsplash.com/photo-1623605931891-d5b95ee98459?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&q=80",
    name: "Bashir Bashir",
    title: "Head Of Operation",
  },
  {
    avatar:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    name: "Zubairu Saeed",
    title: "CTO",
  },
  {
    avatar:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    name: "Abubakar Jamilu",
    title: "Engineer",
  },
];

const products = {
  consolidatedShipping: {
    title: "Consolidated Shipping: Streamlined Logistics",
    description:
      "Our Consolidated Shipping service combines multiple shipments into one, reducing costs and improving efficiency. Designed for businesses looking to optimize their logistics, our solution ensures timely and cost-effective deliveries.",
    image:
      "https://images.pexels.com/photos/6169660/pexels-photo-6169660.jpeg?auto=compress&cs=tinysrgb&w=800", // Replace with the actual path to the Consolidated Shipping image
  },
  ecommerceSolutions: {
    title: "E-Commerce Solutions: Empowering Online Retail",
    description:
      "Our E-Commerce Solutions provide everything you need to launch and manage a successful online store. From storefront design to payment processing, we offer a comprehensive suite of tools to enhance your online retail experience.",
    image:
      "https://images.pexels.com/photos/3769747/pexels-photo-3769747.jpeg?auto=compress&cs=tinysrgb&w=800", // Replace with the actual path to the E-Commerce Solutions image
  },
  daas: {
    title: "DaaS: Data as a Service",
    description:
      "Our Data as a Service (DaaS) offering includes Tech Consulting, Risk Advisory, and Safe Data Monetization Gateways. We provide expert guidance and secure solutions for managing and monetizing your data.",
    image:
      "https://images.pexels.com/photos/3183131/pexels-photo-3183131.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  fintechSolutions: {
    title: "Fintech Solutions: Advanced Financial Technologies",
    description:
      "Our Fintech Solutions cover a wide range of services including Mobile Wallets, API Products, API Gateway Solutions, and Integration. Designed to streamline financial transactions and enhance digital banking.",
    image:
      "https://images.pexels.com/photos/7414113/pexels-photo-7414113.jpeg?auto=compress&cs=tinysrgb&w=800", // Replace with the actual path to the Fintech Solutions image
  },
};

const projects = {
  octaship: {
    title: "Octaship: Seamless Shipping Across Borders",
    description:
      "Octaship revolutionizes the shipping industry with a platform designed for seamless logistics between Canada and Nigeria. Our solution simplifies cross-border shipping, offering a user-friendly interface and efficient management of international shipments.",
    image: OctaShipLogo, // Replace with the actual path to the Octaship image
  },
  fintech: {
    title: "Fintech Solutions: Innovating Financial Transactions",
    description:
      "Our Fintech Solutions offer advanced financial technologies including mobile wallets, API integrations, and more. Designed for a digital-first world, our solutions streamline transactions and enhance financial management.",
    image: FintechLogo, // Replace with the actual path to the Fintech image
  },
};
export default function Home() {
  const { settings } = useAuth();
  const [selectedProject, setSelectedProject] = useState("octaship");
  const [selectedProduct, setSelectedProduct] = useState(
    "daas"
  );

  const footerNavs = [
    {
      href: "/privacy-policy",
      name: "Privacy Policy",
    },
    {
      href: "/terms",
      name: "Terms",
    },
    {
      href: "javascript:void()",
      name: "Support",
    },
  ];

  return (
    <div className="animated-text" style={{ overflowX: "hidden" }}>
      <div className="max-w-[85rem] mt-[20px] lg:mt-[90px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid lg:grid-cols-7 lg:gap-x-8 xl:gap-x-12 lg:items-center">
          <div className="lg:col-span-3">
            <h1 className="block text-3xl font-bold text-gray-800 sm:text-4xl md:text-5xl lg:text-6xl">
              Building a Better World
            </h1>
            <p className="mt-3 text-lg text-gray-800">
              At Octagonic, we're committed to crafting innovative solutions
              that drive meaningful impact. With a focus on excellence and
              sustainability, we turn bold ideas into transformative results,
              building a brighter future for all.
            </p>

            <div className="mt-6 lg:mt-12">
              <span className="text-xs font-medium text-gray-800 uppercase">
                GLOBAL STANDARDS WE UPHOLD:
              </span>

              <div className="mt-4 flex gap-x-8">
                <img
                  className="w-12 h-12"
                  width="106"
                  src="https://nationalprocessing.com/wp-content/uploads/2021/07/2032868.png"
                  height="36"
                />
                <img
                  className="w-12 h-12"
                  src="https://cdn.punchng.com/wp-content/uploads/2023/08/31033914/ISO-certification.png"
                />
                <img
                  className="w-12 h-12"
                  width="106"
                  src="https://www.loginradius.com/wp-content/uploads/2019/10/PNG_GDPR-e1672263252689.png"
                  height="36"
                />
              </div>
            </div>
          </div>

          <div className="lg:col-span-4 mt-10 lg:mt-0">
            <img
              className="w-full rounded-xl"
              alt="Hero Image"
              src="https://images.pexels.com/photos/5429000/pexels-photo-5429000.jpeg"
            />
          </div>
        </div>
      </div>


      {/* Section Features  */}
      <section>
        {/* Container */}
        <div className="mx-auto max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
          {/* Heading Div */}
          <div className="mx-auto w-full max-w-3xl text-center">
            <h2 className="text-3xl font-semibold md:text-5xl">
              What{" "}
              <span className="bg-blue-700 bg-cover bg-center bg-no-repeat px-4 text-white">
                we do
              </span>
            </h2>
            <div className="mx-auto mb-8 mt-4 max-w-[528px] md:mb-12 lg:mb-16">
              <p className="text-[#636262]">
                At Octagonic, we’re redefining possibilities with cutting-edge
                solutions in shipping, financial technology, and data analytics.
              </p>
            </div>
          </div>
          {/* Features Div */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-8 md:grid-cols-3 lg:gap-12">
            {/* Feature Item */}
            <div className="relative mb-8 flex flex-col rounded-2xl border border-solid border-black p-8 [box-shadow:rgb(0,_0,_0)_9px_9px] lg:mb-4">
              <div className="absolute -top-8 bottom-auto left-auto right-4 flex h-16 w-16 flex-col items-center justify-center rounded-full border border-solid border-[#9b9b9b] bg-white [box-shadow:rgb(0,_0,_0)_0px_5px] lg:right-8">
                <img
                  alt=""
                  width={40}
                  src={Icon2}
                  className="relative z-10 inline-block h-15"
                />
                <div className="absolute z-0 h-8 w-8 rounded-full"></div>
              </div>
              <p className="mb-4 text-xl font-semibold">
                Shipping & logistic solutions
              </p>
              <p>
                We deliver seamless global Shipping, logistics and e-commerce
                solutions.
              </p>
            </div>
            {/* Feature Item */}
            <div className="relative mb-8 flex flex-col rounded-2xl border border-solid border-black p-8 [box-shadow:rgb(0,_0,_0)_9px_9px] lg:mb-4">
              <div className="absolute -top-8 bottom-auto left-auto right-4 flex h-16 w-16 flex-col items-center justify-center rounded-full border border-solid border-[#9b9b9b] bg-white [box-shadow:rgb(0,_0,_0)_0px_5px] lg:right-8">
                <img
                  alt=""
                  width={40}
                  src={Icon4}
                  className="relative z-10 inline-block h-15"
                />
                <div className="absolute z-0 h-8 w-8 rounded-full"></div>
              </div>
              <p className="mb-4 text-xl font-semibold">Financial Technology</p>
              <p>
                We offer Innovative FinTech solutions for a Digital-First World.
              </p>
            </div>
            {/* Feature Item */}
            <div className="relative mb-8 flex flex-col rounded-2xl border border-solid border-black p-8 [box-shadow:rgb(0,_0,_0)_9px_9px] lg:mb-4">
              <div className="absolute -top-8 bottom-auto left-auto right-4 flex h-16 w-16 flex-col items-center justify-center rounded-full border border-solid border-[#8B4000] bg-white [box-shadow:rgb(0,_0,_0)_0px_5px] lg:right-8">
                <img
                  alt=""
                  width={40}
                  src={Icon}
                  className="relative z-10 inline-block h-15"
                />
                <div className="absolute z-0 h-8 w-8 rounded-full"></div>
              </div>
              <p className="mb-4 text-xl font-semibold">Big Data Engine</p>
              <p>
                We use latest advancements in Ai and machine learning to
                maximize our value proposition.
              </p>
            </div>
          </div>
        </div>
      </section>
      
      <div className="w-full">
        <h2 className="text-3xl text-center font-semibold md:text-5xl">
          Our Products & Services
        </h2>

        <section className="mx-auto my-10 bg-neutral-900 flex max-w-xl flex-col rounded-3xl border p-4 sm:px-16 sm:py-16 lg:max-w-screen-lg lg:flex-row">
          <div className="lg:w-1/2 lg:pr-8">
            <div className="flex flex-wrap">
              <button
                onClick={() => setSelectedProduct("daas")}
                className={`mr-4 mb-2 rounded-full px-4 py-2 font-medium transition ${
                  selectedProduct === "daas"
                    ? "bg-blue-700 text-white"
                    : "bg-gray-200 text-black"
                }`}
              >
                DaaS
              </button>
              <button
                onClick={() => setSelectedProduct("consolidatedShipping")}
                className={`mr-4 mb-2 rounded-full px-4 py-2 font-medium transition ${
                  selectedProduct === "consolidatedShipping"
                    ? "bg-blue-700 text-white"
                    : "bg-gray-200 text-black"
                }`}
              >
                Consolidated Shipping
              </button>
              <button
                onClick={() => setSelectedProduct("ecommerceSolutions")}
                className={`mr-4 mb-2 rounded-full px-4 py-2 font-medium transition ${
                  selectedProduct === "ecommerceSolutions"
                    ? "bg-blue-700 text-white"
                    : "bg-gray-200 text-black"
                }`}
              >
                E-Commerce Solutions
              </button>
              <button
                onClick={() => setSelectedProduct("fintechSolutions")}
                className={`mr-4 mb-2 rounded-full px-4 py-2 font-medium transition ${
                  selectedProduct === "fintechSolutions"
                    ? "bg-blue-700 text-white"
                    : "bg-gray-200 text-black"
                }`}
              >
                Fintech Solutions
              </button>
            </div>
            <div className="mt-4">
              <h3 className="text-3xl font-medium text-white">
                {products[selectedProduct]?.title || "Select a Product"}
              </h3>
              <p className="mt-6 text-gray-300">
                {products[selectedProduct]?.description ||
                  "Please select a product to see details."}
              </p>
            </div>
          </div>
          <div className="-order-1 mb-8 lg:order-1 lg:mb-0 lg:w-1/2">
            <img
              className="border-[#8B4000]/10 rounded-3xl border shadow-md"
              src={products[selectedProduct]?.image} // Replace DefaultImage with a placeholder image path if needed
              alt={products[selectedProduct]?.title || "Product Image"}
            />
          </div>
        </section>
      </div>

      <h2 className="text-3xl mt-[200px] text-center font-semibold md:text-5xl">
        Our Projects
      </h2>

      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="group flex flex-col h-full bg-white rounded-2xl border border-solid border-black p-8 [box-shadow:rgb(0,_0,_0)_9px_9px] border border-gray-200 shadow-sm rounded-xl">
            <div className="h-52 flex flex-col justify-center items-center bg-blue-700 rounded-t-xl">
              <img
                src={OctaShipLogo}
                className="w-[60px] rounded-lg bg-white"
              />
            </div>
            <div className="p-4 md:p-6">
              <span className="block mb-1 text-xs font-semibold uppercase text-blue-600">
                Octaship
              </span>
              <h3 className="text-xl font-semibold text-gray-800">
                Seamless global shipping and logistics solutions.
              </h3>
              <p className="mt-3 text-gray-500">
                We combine cutting-edge logistics technology, streamlined
                processes, and a vast international network to offer the fastest
                delivery times and the highest level of reliability
              </p>
            </div>
            <div className="mt-auto flex border-t border-gray-200 divide-x divide-gray-200">
              <button
                className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-es-xl text-blue-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                onClick={() => window.open("https://octaship.com", "_blank")}
              >
                View project
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-r from-black to-black">
        <div className="mx-auto h-full px-4 py-10 sm:max-w-xl md:max-w-full md:px-24 lg:max-w-screen-xl lg:px-8 lg:py-16 xl:py-28">
          <div className="flex flex-col items-center justify-between">
            <div className="">
              <div className="">
                <h1 className="mb-6 text-center text-3xl md:text-5xl font-light tracking-tight text-white sm:text-5xl lg:text-7xl">
                  Our Global Presence in{" "}
                  <span className="my-1 inline-block font-serif font-bold text-white">
                    {" "}
                    Multiple Countries{" "}
                  </span>
                </h1>
                <p className="text-center text-base px-2 text-gray-100">
                  Discover the countries where we are making an impact and
                  driving innovation.
                </p>
              </div>
              <div className="w-full  divide-gray-300/30 mt-12 flex flex-row  flex-wrap items-center pl-[10px] lg:justify-center space-y-3 text-sm text-gray-700 sm:flex-row sm:items-center sm:space-y-0 sm:divide-x">
                <div className="w-[50%] lg:w-auto flex  space-x-2 px-4">
                  <span className="shrink-0 text-[26px] flex h-14 w-14 items-center justify-center rounded-xl bg-white p-2.5 text-purple-500">
                    🇨🇦
                  </span>
                  <p className="text-gray-100 mt-[15px]">Canada</p>
                </div>
                <div className="flex w-[50%] lg:w-auto space-x-2 px-4">
                  <span className="shrink-0 text-[26px] flex h-14 w-14 items-center justify-center rounded-xl bg-white p-2.5 text-rose-500">
                    🇳🇬
                  </span>
                  <p className="text-gray-100  mt-[15px]">Nigeria</p>
                </div>
                <div className="flex w-[50%] lg:w-auto space-x-2 px-4">
                  <span className="shrink-0 text-[26px] flex h-14 w-14 items-center justify-center rounded-xl bg-white p-2.5 text-teal-500">
                    🇬🇭
                  </span>
                  <p className="text-gray-100 mt-[15px]">Ghana</p>
                </div>
                <div className="flex w-[50%] lg:w-auto space-x-2 px-4">
                  <span className="shrink-0 text-[26px] flex h-14 w-14 items-center justify-center rounded-xl bg-white p-2.5 text-green-500">
                    🇪🇹
                  </span>
                  <p className="text-gray-100 mt-[15px]">Ethiopia</p>
                </div>
                <div className="flex w-[50%] lg:w-auto space-x-2 px-4">
                  <span className="shrink-0 text-[26px] flex h-14 w-14 items-center justify-center rounded-xl bg-white p-2.5 text-yellow-500">
                    🇸🇳
                  </span>
                  <p className="text-gray-100 mt-[15px]">Senegal</p>
                </div>

                <div className="flex w-[50%] lg:w-auto space-x-2 px-4">
                  <span className="shrink-0 text-[26px] flex h-14 w-14 items-center justify-center rounded-xl bg-white p-2.5 text-blue-500">
                    🇸🇬
                  </span>
                  <p className="text-gray-100 mt-[15px]">Singapore</p>
                </div>

                <div className="flex w-[50%] lg:w-auto space-x-2 px-4">
                  <span className="shrink-0 text-[26px] flex h-14 w-14 items-center justify-center rounded-xl bg-white p-2.5 text-red-500">
                    🇲🇾
                  </span>
                  <p className="text-gray-100 mt-[15px]">Malaysia</p>
                </div>
              </div>
            </div>
            <div className="relative mt-20 hidden lg:block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mx-auto my-6 h-10 w-10 animate-bounce rounded-full bg-blue-50 p-2 lg:hidden"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16 17l-4 4m0 0l-4-4m4 4V3"
                ></path>
              </svg>
              <div
                className="w-fit mx-auto flex overflow-hidden rounded-3xl bg-orange-400"
                role="image-container"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-blue-700">
        <div className="max-w-5xl px-4 xl:px-0 py-10 lg:pt-20 lg:pb-20 mx-auto">
          <div className="max-w-3xl mb-10 lg:mb-14">
            <h2 className="text-white font-semibold text-2xl md:text-4xl md:leading-tight">
              Join Our Innovative Team
            </h2>
            <p className="mt-1 text-white">
              At Octagonic, we’re at the forefront of technological advancement,
              transforming industries with our cutting-edge solutions.
            </p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-16 lg:items-center">
            <div className="aspect-w-16 aspect-h-9 lg:aspect-none">
              <img
                className="w-full object-cover rounded-xl"
                src="https://images.unsplash.com/photo-1587614203976-365c74645e83?q=80&w=480&h=600&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Features Image"
              />
            </div>

            <div>
              <div className="mb-4">
                <h3 className="text-[#ff0] text-xs font-medium uppercase">
                  Benefits
                </h3>
              </div>
              <div className="space-y-6 lg:space-y-10">
                <div className="flex gap-x-5 sm:gap-x-8">
                  <span className="shrink-0 inline-flex justify-center items-center size-[46px] rounded-full text-gray-800 shadow-sm mx-auto">
                    <img
                      className="shrink-0 size-5"
                      width="34"
                      height="34"
                      src={LoveIco}
                    />
                  </span>
                  <div className="grow">
                    <h3 className="text-base sm:text-lg font-semibold text-white">
                      Impactful Work
                    </h3>
                    <p className="mt-1 text-white">
                      Join a team dedicated to creating solutions that drive
                      global change. Your work will directly impact industries
                      and communities around the world.
                    </p>
                  </div>
                </div>

                <div className="flex gap-x-5 sm:gap-x-8">
                  <span className="shrink-0 inline-flex justify-center items-center size-[46px] rounded-full text-gray-800 shadow-sm mx-auto">
                    <img
                      className="shrink-0 size-5"
                      width="37"
                      height="37"
                      src={HandShake}
                    />
                  </span>
                  <div className="grow">
                    <h3 className="text-base sm:text-lg font-semibold text-white">
                      Collaborative Environment
                    </h3>
                    <p className="mt-1 text-white">
                      Experience a culture of collaboration where your ideas are
                      valued. Work alongside innovative thinkers and industry
                      experts in a supportive and dynamic environment.
                    </p>
                  </div>
                </div>

                <div className="flex gap-x-5 sm:gap-x-8">
                  <span className="shrink-0 inline-flex justify-center items-center size-[46px] rounded-full text-gray-800 shadow-sm mx-auto">
                    <img
                      className="shrink-0 size-5"
                      width="34"
                      height="34"
                      src={PromotionIc}
                    />
                  </span>
                  <div className="grow">
                    <h3 className="text-base sm:text-lg font-semibold text-white">
                      Growth Opportunities
                    </h3>
                    <p className="mt-1 text-white">
                      Grow your career with opportunities for advancement and
                      continuous learning.
                    </p>
                  </div>
                </div>
              </div>

              <a
                className="group mt-[30px] inline-flex items-center gap-x-2 py-2 px-3 bg-white font-medium text-sm text-neutral-800 rounded-full focus:outline-none"
                href="#"
              >
                View openings
              </a>
            </div>
          </div>
        </div>
      </div>

      <footer className="relative overflow-hidden bg-neutral-900">
        <svg
          className="absolute -bottom-20 start-1/2 w-[1900px] transform -translate-x-1/2"
          width="2745"
          height="488"
          viewBox="0 0 2745 488"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 330.864C232.505 403.801 853.749 527.683 1482.69 439.719C2111.63 351.756 2585.54 434.588 2743.87 487"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 308.873C232.505 381.81 853.749 505.692 1482.69 417.728C2111.63 329.765 2585.54 412.597 2743.87 465.009"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 286.882C232.505 359.819 853.749 483.701 1482.69 395.738C2111.63 307.774 2585.54 390.606 2743.87 443.018"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 264.891C232.505 337.828 853.749 461.71 1482.69 373.747C2111.63 285.783 2585.54 368.615 2743.87 421.027"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 242.9C232.505 315.837 853.749 439.719 1482.69 351.756C2111.63 263.792 2585.54 346.624 2743.87 399.036"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 220.909C232.505 293.846 853.749 417.728 1482.69 329.765C2111.63 241.801 2585.54 324.633 2743.87 377.045"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 198.918C232.505 271.855 853.749 395.737 1482.69 307.774C2111.63 219.81 2585.54 302.642 2743.87 355.054"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 176.927C232.505 249.864 853.749 373.746 1482.69 285.783C2111.63 197.819 2585.54 280.651 2743.87 333.063"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 154.937C232.505 227.873 853.749 351.756 1482.69 263.792C2111.63 175.828 2585.54 258.661 2743.87 311.072"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 132.946C232.505 205.882 853.749 329.765 1482.69 241.801C2111.63 153.837 2585.54 236.67 2743.87 289.082"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 110.955C232.505 183.891 853.749 307.774 1482.69 219.81C2111.63 131.846 2585.54 214.679 2743.87 267.091"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 88.9639C232.505 161.901 853.749 285.783 1482.69 197.819C2111.63 109.855 2585.54 192.688 2743.87 245.1"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 66.9729C232.505 139.91 853.749 263.792 1482.69 175.828C2111.63 87.8643 2585.54 170.697 2743.87 223.109"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 44.9819C232.505 117.919 853.749 241.801 1482.69 153.837C2111.63 65.8733 2585.54 148.706 2743.87 201.118"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 22.991C232.505 95.9276 853.749 219.81 1482.69 131.846C2111.63 43.8824 2585.54 126.715 2743.87 179.127"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 1C232.505 73.9367 853.749 197.819 1482.69 109.855C2111.63 21.8914 2585.54 104.724 2743.87 157.136"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
        </svg>

        <div className="relative z-10 text-white">
          <div className="w-full max-w-5xl px-4 xl:px-0 py-10 lg:pt-16 mx-auto">
            <div className="max-w-lg sm:mx-auto sm:text-center">
              <img src={WhiteLogo} className="w-[200px] sm:mx-auto" />
              <p className="leading-relaxed mt-2 text-[15px]">
                At Octagonic, we are dedicated to providing innovative solutions
                that drive growth and efficiency. Our expertise spans across
                various domains, including shipping logistics, e-commerce, tech
                consulting, and fintech. We strive to deliver exceptional
                services that cater to the evolving needs of our clients.
              </p>
            </div>
            <ul className="items-center justify-center mt-8 space-y-5 sm:flex sm:space-x-4 sm:space-y-0">
              {/* {footerNavs.map((item, idx) => (
                <li className=" hover:text-gray-800">
                  <a key={idx} href={item.href}>
                    {item.name}
                  </a>
                </li>
              ))} */}
            </ul>
            <div className="mt-8 items-center justify-between sm:flex">
              <div className="mt-4 sm:mt-0">
                &copy; 2024 Octagonic All rights reserved.
              </div>
              <div className="mt-6 sm:mt-0">
                <ul className="flex items-center space-x-4">
                  <li className="w-10 h-10 border rounded-full flex items-center justify-center">
                    <a href="javascript:void()">
                      <svg
                        className="svg-icon w-6 h-6 text-blue-400"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill="none"
                          d="M18.258,3.266c-0.693,0.405-1.46,0.698-2.277,0.857c-0.653-0.686-1.586-1.115-2.618-1.115c-1.98,0-3.586,1.581-3.586,3.53c0,0.276,0.031,0.545,0.092,0.805C6.888,7.195,4.245,5.79,2.476,3.654C2.167,4.176,1.99,4.781,1.99,5.429c0,1.224,0.633,2.305,1.596,2.938C2.999,8.349,2.445,8.19,1.961,7.925C1.96,7.94,1.96,7.954,1.96,7.97c0,1.71,1.237,3.138,2.877,3.462c-0.301,0.08-0.617,0.123-0.945,0.123c-0.23,0-0.456-0.021-0.674-0.062c0.456,1.402,1.781,2.422,3.35,2.451c-1.228,0.947-2.773,1.512-4.454,1.512c-0.291,0-0.575-0.016-0.855-0.049c1.588,1,3.473,1.586,5.498,1.586c6.598,0,10.205-5.379,10.205-10.045c0-0.153-0.003-0.305-0.01-0.456c0.7-0.499,1.308-1.12,1.789-1.827c-0.644,0.28-1.334,0.469-2.06,0.555C17.422,4.782,17.99,4.091,18.258,3.266"
                        ></path>
                      </svg>
                    </a>
                  </li>

                  <li className="w-10 h-10 border rounded-full flex items-center justify-center">
                    <a href="javascript:void()">
                      <svg
                        className="svg-icon w-6 h-6 text-blue-700"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill="none"
                          d="M11.344,5.71c0-0.73,0.074-1.122,1.199-1.122h1.502V1.871h-2.404c-2.886,0-3.903,1.36-3.903,3.646v1.765h-1.8V10h1.8v8.128h3.601V10h2.403l0.32-2.718h-2.724L11.344,5.71z"
                        ></path>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <style jsx>{`
              .svg-icon path,
              .svg-icon polygon,
              .svg-icon rect {
                fill: currentColor;
              }
            `}</style>
          </div>
        </div>
      </footer>
    </div>
  );
}

// <section className="w-screen bg-white">
//   <div className="mx-auto w-full py-2 sm:max-w-screen-sm md:max-w-screen-md md:py-12 lg:py-12">
//     <div className="flex items-center">
//       <div className="w-full">
//         <div className="flex justify-center md:pb-8 lg:pb-8">
//           <div className="md:w-5/6 w-full text-center lg:w-5/6">
//             <h2 className="text-3xl mb-[20px] font-semibold md:text-5xl">
//               Our Global Presence
//             </h2>
//             <p className="mb-4 font-sans text-base font-light leading-7 text-gray-500 md:text-xl md:leading-7 lg:text-xl lg:leading-7">
//               We are proudly operating in multiple regions around the world,
//               ensuring our innovative solutions reach diverse markets and
//               communities. Our extensive network spans across continents,
//               allowing us to deliver exceptional services globally.
//             </p>
//           </div>
//         </div>
//         <div className="flex flex-wrap items-start justify-center px-2">
//           <div className="w-full divide-y divide-gray-300">
//             <div
//               href=""
//               className="block w-full cursor-pointer py-4 font-sans duration-300 ease-in-out hover:bg-purple-50 md:py-6 lg:py-6"
//             >
//               <div className="flex flex-wrap items-center justify-between px-0 md:px-4 lg:px-4">
//                 <div className="md:w-1/4 mr-0 w-5/6 pl-4 md:mr-4 md:pl-0 lg:mr-4 lg:w-1/4 lg:pl-0">
//                   <h3 className="text-xl font-serif leading-7">Canada</h3>
//                 </div>
//                 <div className="md:w-1/2 w-5/6 pl-4 md:pl-0 lg:w-1/2 lg:pl-0"></div>
//                 <div className="md:w-1/12 w-1/12 text-right lg:w-1/12">
//                   <div className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-solid border-current font-normal text-blue-600 duration-150 ease-in-out">
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       viewBox="0 0 20 20"
//                       fill="currentColor"
//                       className="block h-5 w-5"
//                     >
//                       <path
//                         fill-rule="evenodd"
//                         d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
//                         clip-rule="evenodd"
//                       ></path>
//                     </svg>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div
//               href=""
//               className="block w-full cursor-pointer py-4 font-sans duration-300 ease-in-out hover:bg-purple-50 md:py-6 lg:py-6"
//             >
//               <div className="flex flex-wrap items-center justify-between px-0 md:px-4 lg:px-4">
//                 <div className="md:w-1/4 mr-0 w-5/6 pl-4 md:mr-4 md:pl-0 lg:mr-4 lg:w-1/4 lg:pl-0">
//                   <h3 className="text-xl font-serif leading-7">Nigeria</h3>
//                 </div>
//                 <div className="md:w-1/2 w-5/6 pl-4 md:pl-0 lg:w-1/2 lg:pl-0"></div>
//                 <div className="md:w-1/12 w-1/12 text-right lg:w-1/12">
//                   <div className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-solid border-current font-normal text-blue-600 duration-150 ease-in-out">
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       viewBox="0 0 20 20"
//                       fill="currentColor"
//                       className="block h-5 w-5"
//                     >
//                       <path
//                         fill-rule="evenodd"
//                         d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
//                         clip-rule="evenodd"
//                       ></path>
//                     </svg>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div
//               href=""
//               className="block w-full cursor-pointer py-4 font-sans duration-300 ease-in-out hover:bg-purple-50 md:py-6 lg:py-6"
//             >
//               <div className="flex flex-wrap items-center justify-between px-0 md:px-4 lg:px-4">
//                 <div className="md:w-1/4 mr-0 w-5/6 pl-4 md:mr-4 md:pl-0 lg:mr-4 lg:w-1/4 lg:pl-0">
//                   <h3 className="text-xl font-serif leading-7">Ghana</h3>
//                 </div>
//                 <div className="md:w-1/2 w-5/6 pl-4 md:pl-0 lg:w-1/2 lg:pl-0"></div>
//                 <div className="md:w-1/12 w-1/12 text-right lg:w-1/12">
//                   <div className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-solid border-current font-normal text-blue-600 duration-150 ease-in-out">
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       viewBox="0 0 20 20"
//                       fill="currentColor"
//                       className="block h-5 w-5"
//                     >
//                       <path
//                         fill-rule="evenodd"
//                         d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
//                         clip-rule="evenodd"
//                       ></path>
//                     </svg>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div
//               href=""
//               className="block w-full cursor-pointer py-4 font-sans duration-300 ease-in-out hover:bg-purple-50 md:py-6 lg:py-6"
//             >
//               <div className="flex flex-wrap items-center justify-between px-0 md:px-4 lg:px-4">
//                 <div className="md:w-1/4 mr-0 w-5/6 pl-4 md:mr-4 md:pl-0 lg:mr-4 lg:w-1/4 lg:pl-0">
//                   <h3 className="text-xl font-serif leading-7">Ethiopia</h3>
//                 </div>
//                 <div className="md:w-1/2 w-5/6 pl-4 md:pl-0 lg:w-1/2 lg:pl-0"></div>
//                 <div className="md:w-1/12 w-1/12 text-right lg:w-1/12">
//                   <div className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-solid border-current font-normal text-blue-600 duration-150 ease-in-out">
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       viewBox="0 0 20 20"
//                       fill="currentColor"
//                       className="block h-5 w-5"
//                     >
//                       <path
//                         fill-rule="evenodd"
//                         d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
//                         clip-rule="evenodd"
//                       ></path>
//                     </svg>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div
//               href=""
//               className="block w-full cursor-pointer py-4 font-sans duration-300 ease-in-out hover:bg-purple-50 md:py-6 lg:py-6"
//             >
//               <div className="flex flex-wrap items-center justify-between px-0 md:px-4 lg:px-4">
//                 <div className="md:w-1/4 mr-0 w-5/6 pl-4 md:mr-4 md:pl-0 lg:mr-4 lg:w-1/4 lg:pl-0">
//                   <h3 className="text-xl font-serif leading-7">Senegal</h3>
//                 </div>
//                 <div className="md:w-1/2 w-5/6 pl-4 md:pl-0 lg:w-1/2 lg:pl-0"></div>
//                 <div className="md:w-1/12 w-1/12 text-right lg:w-1/12">
//                   <div className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-solid border-current font-normal text-blue-600 duration-150 ease-in-out">
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       viewBox="0 0 20 20"
//                       fill="currentColor"
//                       className="block h-5 w-5"
//                     >
//                       <path
//                         fill-rule="evenodd"
//                         d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
//                         clip-rule="evenodd"
//                       ></path>
//                     </svg>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div
//               href=""
//               className="block w-full cursor-pointer py-4 font-sans duration-300 ease-in-out hover:bg-purple-50 md:py-6 lg:py-6"
//             >
//               <div className="flex flex-wrap items-center justify-between px-0 md:px-4 lg:px-4">
//                 <div className="md:w-1/4 mr-0 w-5/6 pl-4 md:mr-4 md:pl-0 lg:mr-4 lg:w-1/4 lg:pl-0">
//                   <h3 className="text-xl font-serif leading-7">Singapore</h3>
//                 </div>
//                 <div className="md:w-1/2 w-5/6 pl-4 md:pl-0 lg:w-1/2 lg:pl-0"></div>
//                 <div className="md:w-1/12 w-1/12 text-right lg:w-1/12">
//                   <div className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-solid border-current font-normal text-blue-600 duration-150 ease-in-out">
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       viewBox="0 0 20 20"
//                       fill="currentColor"
//                       className="block h-5 w-5"
//                     >
//                       <path
//                         fill-rule="evenodd"
//                         d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
//                         clip-rule="evenodd"
//                       ></path>
//                     </svg>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div
//               href=""
//               className="block w-full cursor-pointer py-4 font-sans duration-300 ease-in-out hover:bg-purple-50 md:py-6 lg:py-6"
//             >
//               <div className="flex flex-wrap items-center justify-between px-0 md:px-4 lg:px-4">
//                 <div className="md:w-1/4 mr-0 w-5/6 pl-4 md:mr-4 md:pl-0 lg:mr-4 lg:w-1/4 lg:pl-0">
//                   <h3 className="text-xl font-serif leading-7">Malaysia</h3>
//                 </div>
//                 <div className="md:w-1/2 w-5/6 pl-4 md:pl-0 lg:w-1/2 lg:pl-0"></div>
//                 <div className="md:w-1/12 w-1/12 text-right lg:w-1/12">
//                   <div className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-solid border-current font-normal text-blue-600 duration-150 ease-in-out">
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       viewBox="0 0 20 20"
//                       fill="currentColor"
//                       className="block h-5 w-5"
//                     >
//                       <path
//                         fill-rule="evenodd"
//                         d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
//                         clip-rule="evenodd"
//                       ></path>
//                     </svg>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </section>;
