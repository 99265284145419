import React from "react";
import { IoCallOutline } from "react-icons/io5";
import "./home.animation.css";

export default function ContactUs() {
  return (
    <div className="animated-text max-w-7xl px-4 lg:px-6 lg:px-8 py-12 lg:py-24 mx-auto">
      <div className=" pb-[50px] sm:mb-10 max-w-2xl text-center mx-auto">
        <h2 className="font-medium text-black text-2xl sm:text-4xl">
          Got questions? We're here to help
        </h2>
        <p className="text-[#636262] pt-[15px]">
          At Octagonic, we provide innovative solutions in shipping, fintech,
          and big data analytics. Reach out to discover how we can assist you.
        </p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 lg:items-center gap-6 md:gap-8 md:ml-[50px] lg:gap-12">
        <div className="aspect-w-16 aspect-h-6 lg:aspect-h-14 overflow-hidden bg-gray-100 rounded-2xl">
          <img
            className="group-hover:scale-105 group-focus:scale-105 transition-transform duration-500 ease-in-out object-cover rounded-2xl"
            src="https://images.pexels.com/photos/9301294/pexels-photo-9301294.jpeg?auto=compress&cs=tinysrgb&w=800"
          />
        </div>

        <div className="space-y-8 lg:space-y-16">
          <div>
            <h3 className="mb-5 font-semibold text-black">Our address</h3>

            <div className="grid sm:grid-cols-2 gap-4 sm:gap-6 md:gap-8 lg:gap-12">
              <div className="flex gap-4">
                <svg
                  className="shrink-0 size-5 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z"></path>
                  <circle cx="12" cy="10" r="3"></circle>
                </svg>

                <div className="grow">
                  <p className="text-sm text-gray-600">Headquarters</p>
                  <address className="mt-1 text-black not-italic">
                    5000 Highway 7, Markham, ON. L34 4M9. Canada <br />
                  </address>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h3 className="mb-5 font-semibold text-black">Our contacts</h3>

            <div className="grid sm:grid-cols-2 gap-4 sm:gap-6 md:gap-8 lg:gap-12">
              <div className="flex gap-4">
                <svg
                  className="shrink-0 size-5 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M21.2 8.4c.5.38.8.97.8 1.6v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V10a2 2 0 0 1 .8-1.6l8-6a2 2 0 0 1 2.4 0l8 6Z"></path>
                  <path d="m22 10-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 10"></path>
                </svg>

                <div className="grow">
                  <p className="text-sm text-gray-600">Email us</p>
                  <p>
                    <a
                      className="relative inline-block font-medium text-black before:absolute before:bottom-0.5 before:start-0 before:-z-[1] before:w-full before:h-1 before:bg-blue-400 hover:before:bg-black focus:outline-none focus:before:bg-black"
                      href="mailto:example@site.so"
                    >
                      general@octagonic.io
                    </a>
                  </p>
                </div>
              </div>
              <div className="flex gap-4">
                <IoCallOutline size={25} className="shrink-0 size-5 text-gray-500" />

                <div className="grow">
                  <p className="text-sm text-gray-600">Phone Number</p>
                  <p>
                    <a
                      className="relative inline-block font-medium text-black hover:before:bg-black focus:outline-none focus:before:bg-black"
                      href="tel:"
                    >
                      +16478654531
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
